import React from 'react';
import { Chip, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";

const SelectedFilters = ({ filters }) => {
  const { t } = useTranslation();
  const { sports, countries, leagues, } = filters;

  return (
    <div style={{ margin: '20px' }}>
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px' }}>
      <Typography variant="subtitle1" sx={{fontSize: { xs: "12px", md: "16px" }}}>{t("Selected Filters")}</Typography>
        {sports && sports.length > 0 && sports.map((sport, index) => (
          <Chip key={index} label={sport} />
        ))}
        {countries && countries.length > 0 && countries.map((country, index) => (
          <Chip key={index} label={country.name} />
        ))}
        {leagues && leagues.length > 0 && leagues.map((league, index) => (
          <Chip key={index} label={league.name} />
        ))}
      </div>
    </div>
  );
};

export default SelectedFilters;
