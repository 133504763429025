import * as React from "react";
import { LineChart } from "@mui/x-charts/LineChart";
import { BarChart } from "@mui/x-charts/BarChart";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function LandingPage() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} paddingX={2}>
        <Card>
          <CardContent>
            <Typography
              variant={isSmallScreen ? "body1" : "h6"}
              gutterBottom
              color={theme.palette.primary.main}
            >
              Weekly Bet Traffic
            </Typography>
            <LineChart
              xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
              series={[
                {
                  data: [2, 5.5, 2, 8.5, 1.5, 5],
                  color: theme.palette.primary.main,
                },
              ]}
              width={isSmallScreen ? 250 : 700}
              height={isSmallScreen ? 150 : 300}
            />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} paddingX={2}>
        <Card>
          <CardContent>
            <Typography
              variant={isSmallScreen ? "body1" : "h6"}
              gutterBottom
              color={theme.palette.primary.main}
            >
              Monthly Bet Traffic
            </Typography>
            <LineChart
              xAxis={[{ data: [1, 2, 3, 4, 5, 6] }]}
              series={[
                {
                  data: [10, 15, 20, 25, 30, 35],
                  color: theme.palette.primary.main,
                },
              ]}
              width={isSmallScreen ? 250 : 700}
              height={isSmallScreen ? 150 : 300}
            />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} paddingX={2}>
        <Card>
          <CardContent>
            <Typography
              variant={isSmallScreen ? "body1" : "h6"}
              gutterBottom
              color={theme.palette.primary.main}
            >
              Top 5 Sports
            </Typography>
            <BarChart
              xAxis={[
                {
                  scaleType: "band",
                  data: ["Football", "Basketball", "Tennis"],
                },
              ]}
              series={[
                {
                  data: [8, 6, 3],
                  color: theme.palette.accent.light,
                },
              ]}
              width={isSmallScreen ? 250 : 700}
              height={isSmallScreen ? 150 : 300}
            />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} sm={6} paddingX={2}>
        <Card>
          <CardContent>
            <Typography
              variant={isSmallScreen ? "body1" : "h6"}
              gutterBottom
              color={theme.palette.primary.main}
            >
              User Growth
            </Typography>
            <BarChart
              xAxis={[
                {
                  scaleType: "band",
                  data: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                },
              ]}
              series={[
                {
                  data: [3, 4, 6, 8, 10, 12],
                  color: theme.palette.accent.light,
                },
              ]}
              width={isSmallScreen ? 250 : 700}
              height={isSmallScreen ? 150 : 300}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
