const urls = {

    get_session: "/core/v1/check/get-session-type",
    check_online_session : (hash_code) => {
        return `/core/v1/check/check-online-session/${hash_code}`
    },

    //BOOKMAKERS
    list_bookmakers : "/core/v1/get-bookmakers",

    //SPORTS
    list_sports : "/core/v1/get-sports",

    //CURRENCIES
    list_currencies : "/core/v1/get-currencies",

    //COUNTRIES
    list_countries : "/core/v1/get-countries",

    //AUTHENTICATION
    
    store_login: "/pa/st/store/v1/auth/access-token",
    partition_login: "/pa/partition/v1/auth/access-token",
    platform_login: "/pl/platform/v1/auth/access-token",
    distributor_login: "/pa/dt/distributor/v1/auth/access-token",
    kiosk_login: "/pa/ki/kiosk/v1/auth/access-token",
    online_user_login: "/pa/on/online/v1/auth/access-token",
    
    //PLATFORM SETTINGS

    list_platform_countries: "/pl/platform/v1/settings/list-main-countries",

    bulk_update_platform_countries: "/pl/platform/v1/settings/bulk-update-main-countries",

    /* list_platform_leagues: "/pl/platform/v1/settings/list-main-countries", */

    list_platform_leagues: (sport_id) => {
        return `/pl/platform/v1/settings/list-main-leagues/${sport_id}`
    },

    bulk_update_platform_leagues: (sport_id) => {
        return `/pl/platform/v1/settings/bulk-update-main-league-statusses/${sport_id}`
    },

    //PARTITION SETTINGS

    list_partition_countries: (partition_id, sport_id) => {
        return `/pl/platform/v1/partition-country-mapping/list/${partition_id}/${sport_id}`
    },

    bulk_update_partition_countries: (partition_id) =>{
        return `/pl/platform/v1/partition-country-mapping/bulk-update/${partition_id} `
    },
    
    list_partition_leagues: (partition_id, sport_id) => {
        return `/pl/platform/v1/partition-league-mapping/list/${partition_id}/${sport_id}`
    },
    
    bulk_update_partition_leagues: (partition_id) =>{
        return `/pl/platform/v1/partition-league-mapping/bulk-update/${partition_id}`
    },

    //PARTITION

    list_partitions : "/pl/platform/v1/partitions/list-partitions",
    get_partition: (id) => {
        return `/pl/platform/v1/partitions/get-parztition/${id}`
    },

    create_partition: "/pl/platform/v1/partitions/create-partition",

    update_partition: (id) => {
        return `/pl/platform/v1/partitions/update-partition/${id}`
    },

    get_sport_bookmaker_mappings: (id) => {
        return `/pl/platform/v1/partitions/list-sport-bookmaker-mappings/${id}`
    },

    update_sport_bookmaker_mapping: (id) => {
        return `/pl/platform/v1/partitions/update-sport-bookmaker-mapping/${id}`
    },

    //DISTRIBUTORS

    list_distributors: "/pa/partition/v1/distributors/list-distributor",

    get_distributor: (id) => {
        return `/pa/partition/v1/distributors/get-distributor/${id}`
    },

    create_distributor: "/pa/partition/v1/distributors/create-distributor",

    update_distributor: (id) => {
        return `/pa/partition/v1/distributors/update-distributor/${id}`
    },

    delete_distributor: (id) => {
        return `/pa/partition/v1/distributors/delete-distributor/${id}`
    },

    //LIST DISTRIBUTOR'S STORE
    list_distributor_store: "/pa/dt/distributor/v1/store/list-distributors-store",

    // LİST PARTITION USERS

    list_partitions_users: "/pa/partition/v1/partition-users/list-users",

    get_partition_users: (id) => {
        return `/pa/partition/v1/partition-users/get-user/${id}`
    },

    create_partition_users: "/pa/partition/v1/partition-users/create-user",

    update_partition_users: (id) => {
        return `/pa/partition/v1/partition-users/update-user/${id}`
    },

    update_partition_users_password: (id) => {
        return `/pa/partition/v1/partition-users/change-partition-user-password/${id}`
    },

    delete_partition_users: (id) => {
        return `/pa/partition/v1/partition-users/delete-partition-user/${id}`
    },

    // LİST DISTRIBUTOR USERS

    list_distributor_users: "/pa/dt/distributor/v1/distributor-users/list-users",

    get_distributor_users: (id) => {
        return `/pa/dt/distributor/v1/distributor-users/get-user/${id}`
    },

    create_distributor_users: "/pa/dt/distributor/v1/distributor-users/create-user",

    update_distributor_users: (id) => {
        return `/pa/dt/distributor/v1/distributor-users/update-user/${id}`
    },

    update_distributor_users_password: (id) => {
        return `/pa/dt/distributor/v1/distributor-users/change-distributor-user-password/${id}`
    },

    delete_distributor_users: (id) => {
        return `/pa/dt/distributor/v1/distributor-users/delete-distributor-user/${id}`
    },

    // LİST STORE USERS

    list_store_users: "/pa/st/store/v1/store-users/list-users",

    get_store_users: (id) => {
        return `/pa/st/store/v1/store-users/get-user/${id}`
    },

    create_store_users: "/pa/st/store/v1/store-users/create-user",

    update_store_users: (id) => {
        return `/pa/st/store/v1/store-users/update-user/${id}`
    },

    update_store_users_password: (id) => {
        return `/pa/st/store/v1/store-users/change-store-user-password/${id}`
    },

    delete_store_users: (id) => {
        return `/pa/st/store/v1/store-users/delete-store-user/${id}`
    },

    //STORE

    list_store: "/pa/dt/distributor/v1/store/list-stores",

    //KIOSK
    
    list_kiosk: "/pa/st/store/v1/kiosk-store/list-kiosk",

    create_kiosk: "/pa/st/store/v1/kiosk-store/create-kiosk",

    update_kiosk: (kiosk_id) => {
        return `/pa/st/store/v1/kiosk-store/update-kiosk/${kiosk_id}`
    },

    delete_kiosk: (kiosk_id) => {
        return `/pa/st/store/v1/kiosk-store/delete-kiosk/${kiosk_id}`
    },

    //List Country and Leagues
    list_football_countries : "/pa/ki/kiosk/v1/for_kiosk/list_countries",

    list_football_leagues : "/pa/ki/kiosk/v1/utils/list_football_leagues",

    //Football Odds
    list_football_odds : "/pa/ki/kiosk/v1/odds/list-football-odds",

    //Odd Detail
    list_football_odds_detail : (fixture_id) =>{
        return `/pa/ki/kiosk/v1/odds/get-detailed-football-odds/${fixture_id}`
    },

    // Coupon 
    create_coupon : "/pa/ki/kiosk/v1/coupon/create-coupons",

    //Update Coupon Status 
    update_coupon_status: (coupon_id) => {
       return `pa/st/store/v1/st-bets/update-coupon-status/${coupon_id}`
    },

    //Store Coupons
    list_coupons : "pa/st/store/v1/st-bets/list-coupons",
    
}   


export default urls;