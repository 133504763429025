import { useRoutes, Navigate } from 'react-router-dom';
import { useStore } from './store/Store';
import MainLayout from './layouts/main';
import Auth from './Auth';
import WentWrong from './pages/WentWrong';
import { isTokenExpired } from './utils/Util';
import Page404 from './pages/Page404';
import LandingPage from './pages/distributor/LandingPage';
import ListDistributorUsers from './pages/distributor/users/ListDistributorUsers';
import AddNewDistributorUser from './pages/distributor/users/addDistributorUser/addDistributorUser';
import ListStores from './pages/distributor/store/ListStores';
import { useEffect } from 'react';
import ListOnlineUsers from './pages/distributor/onlineUsers/ListOnlineUsers';

export default function DistributorRouter() {
  const [store, dispatch] = useStore();
  const distributor = JSON.parse(localStorage.getItem("distributor"))

  useEffect(() => {
    if (isTokenExpired(distributor?.expires_at)) {
      (async () => {
        await dispatch({ type: "STORE_LOGOUT" });
      })();
    }
  }, [distributor, dispatch]);

  const appRedirectElement =
    distributor && store?.distributor?.token ? (
      <MainLayout isAdminLayout={true} />
    ) : (
      <Navigate to="/dt/login" />
    );

  return useRoutes([
    {
      path: "/dt",
      element:appRedirectElement,
      children: [
        { path: "/dt", index: true, element: <Navigate to="/dt/landing" replace /> },
        { path: "/dt/landing", element: <LandingPage /> },
        { path: "/dt/distributor-users", element: <Navigate to="/dt/distributor-users/list-users" /> },
        { path: "/dt/distributor-users/list-users", element: <ListDistributorUsers /> },
        { path: "/dt/distributor-users/add-new-user", element: <AddNewDistributorUser /> },
        { path: "/dt/list-stores", element: <ListStores /> },
        { path: "/dt/on", element: <ListOnlineUsers /> },


      ]
    },
    {
      path: "/dt/login",
      element:(distributor === null || distributor?.token === null ) ? <Auth type="distributor-login" /> : <Navigate to="/dt"/>,
    },
    { path: "*", element: <Page404 /> },
    { path: "/oops", element: <WentWrong /> },
  ]);
}
