import api from "../Api"
import urls from "../Urls";


export const storeLogin = (payload) => {
  return api.post(urls.store_login, payload);
};


export const partitionLogin = (payload) => {
  return api.post(urls.partition_login, payload);
};


export const platformLogin = (payload) => {
  return api.post(urls.platform_login, payload);
}

export const distributorLogin = (payload) => {
  return api.post(urls.distributor_login, payload);
}

export const kioskLogin = (payload) => {
  return api.post(urls.kiosk_login, payload);
}

export const onlineUserLogin = (payload) => {
  return api.post(urls.online_user_login, payload);
}