import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Header from "./Header";
import Sidebar from "./Sidebar";
import FixedSidebar from "./FixedSidebar";
import HoverSidebar from "./HoverSidebar";
import { useLocation } from "react-router-dom";
import {distributorPagesConfig, kioskPagesConfig, partitionPagesConfig, platformPagesConfig, storePagesConfig } from "./PagesConfig";
import PageLayout from "../PageLayout";
import { useStore } from "../../store/Store";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

const RootStyle = styled("div")({
  display: "flex",
  flexDirection: "row",
  minHeight: "100vh",
  overflow: "hidden",
});

const ContentWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  minWidth: "calc(100vw - 75px)",
  maxHeight: "calc(100vh - 75px)",
  overflow: "auto",
  paddingTop: 1,
  paddingBottom: 1,
  backgroundColor: theme.palette.primary.light,
  [theme.breakpoints.up("lg")]: {
    paddingTop: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const PageStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  maxWidth: "95%",
  margin: "auto",
  marginBottom: 25,
  [theme.breakpoints.down("sm")]: {
    maxWidth: "99%"
  },
}));

// ----------------------------------------------------------------------

export default function MainLayout({ isAdminLayout = false }) {

  const {t} = useTranslation();
  const [store, dispatch] = useStore();
  const [isDrawerOpen, setIsDrawerOpen] = useState(store.isDrawerOpen);
  const [isHoverDrawerOpen, setIsHoverDrawerOpen] = useState(false);
  const [hoverNavConfig, setHoverNavConfig] = useState([]);
  const [navTitle, setNavTitle] = useState("");
  const [hoverNavTitle, setHoverNavTitle] = useState("");
  const location = useLocation();
  const [navConfig, setNavConfig] = useState([]);
  const [pagesConfig, setPagesConfig] = useState([]);

  useEffect(() => {

    if(store.userType){
      switch(store.userType){
        case "platform":
          setPagesConfig(platformPagesConfig());
        break;
        case "partition":
          setPagesConfig(partitionPagesConfig());
        break;
        case "distributor":
          setPagesConfig(distributorPagesConfig());
        break;
        case "store":
          setPagesConfig(storePagesConfig());
        break;
        case "kiosk":
          setPagesConfig(kioskPagesConfig());
        break;
        default:
          throw new Error(`Unexpected userType: ${store.userType}`);
      }
    }
   
  }, [store.userType]);


  useEffect(() => {
    dispatch({
      type: "SET_DRAWER_OPEN",
      payload: {
        isDrawerOpen: isDrawerOpen
      },
    })
  }, [isDrawerOpen, dispatch])

  useEffect(() => {

    // Location change is not mean every time a config is gonna change
    // Current location is might be children of parent so in that sitaution config and title mustnt change

    // When config is gonna change?

    // If the location change at main items ? = yes

    let item = pagesConfig.find((item) =>
      location.pathname.includes(item.path)
    );

    if (item?.children) {
      setNavConfig(item.children);
      setNavTitle(item.title);
    }else {
      setNavConfig([]);
      setNavTitle(null);
    }
  }, [location, pagesConfig]);

  useEffect(() => {
    if (isDrawerOpen && isHoverDrawerOpen) {
      setIsHoverDrawerOpen(false);
    }
    // eslint-disable-next-line
  }, [navConfig, isDrawerOpen]);

  const handleToggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  const handleOpenHoverDrawer = (isOpen, config) => {
    setHoverNavConfig(config);
    if (isOpen) {
      if (
        !isDrawerOpen ||
        (isDrawerOpen && navConfig.length !== config.length)
      ) {
        setIsHoverDrawerOpen(isOpen);
      }
    } else {
      setIsHoverDrawerOpen(isOpen);
    }
  };

  const handleMouseEnter = () => {
    if (isHoverDrawerOpen) {
      setIsHoverDrawerOpen(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHoverDrawerOpen(false);
  };

  return (
    <RootStyle>
      <FixedSidebar
        onToggleDrawer={handleToggleDrawer}
        handleOpenHoverDrawer={handleOpenHoverDrawer}
        setHoverNavConfig={setHoverNavConfig}
        setHoverNavTitle={setHoverNavTitle}
        config={pagesConfig}
      />
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <Header />
        <ContentWrapper>
          <Box sx={{ display: "flex", flexGrow: 1 }}>
            <HoverSidebar
              isOpen={isHoverDrawerOpen}
              navConfig={hoverNavConfig}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              hoverNavTitle={t(hoverNavTitle)}
            />
            <Sidebar
              isOpen={isDrawerOpen && navConfig.length > 0}
              onToggleDrawer={handleToggleDrawer}
              navConfig={navConfig}
              navTitle={t(navTitle)}
            />
            <MainStyle className="main-style-container">
              <PageStyle>
                <PageLayout config={pagesConfig} />
                <Outlet />
              </PageStyle>
            </MainStyle>
          </Box>
        </ContentWrapper>
      </Box>
    </RootStyle>
  );
}
