import React, { createContext, useReducer, useContext, useEffect } from "react";
import Reducer from "./Reducer";
import palette from "../theme/palette";

const StoreContext = createContext(null);

const Store = ({ children, initialStateData = {}}) => {

  const platform = JSON.parse(localStorage.getItem("platform"))
  const partition = JSON.parse(localStorage.getItem("partition"))
  const distributor = JSON.parse(localStorage.getItem("distributor"))
  const storeUser = JSON.parse(localStorage.getItem("storeUser"))
  const kiosk = JSON.parse(localStorage.getItem("kiosk"))
  const onlineUser = JSON.parse(localStorage.getItem("onlineUser"))
  const language = localStorage.getItem("language");

  const isDrawerOpen = JSON.parse(localStorage.getItem("isDrawerOpen"))

  const initialState = {
    platform: platform ? platform : null,
    partition: partition ? partition : null,
    distributor: distributor ? distributor : null,
    storeUser: storeUser ? storeUser : null,
    kiosk: kiosk ? kiosk : null,
    onlineUser : onlineUser ? onlineUser : null,
    userType: null,
    language: language ? language : "en-US",
    breadcrumb: [],
    palette: {...palette},
    isDrawerOpen: isDrawerOpen ? isDrawerOpen : false,
    ...initialStateData,
  };


  const [store, dispatch] = useReducer(Reducer, initialState);
  



    useEffect(() => {
      if (initialStateData.userType) {
        dispatch({
          type: "UPDATE_INITIAL",
          payload: {
            services: {},
            userType: initialStateData.userType,
          },
        });
      }
    }, [initialStateData.userType]);




  
  
  return (
    <StoreContext.Provider value={[store, dispatch]}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);
export default Store;
