import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import * as countryFlags from "country-flag-icons/react/3x2";
import { useStore } from "../store/Store";

const languages = [
  { code: "en-US", name: "English", flag: "US" },
  { code: "de-DE", name: "Deutsch", flag: "DE" },
  { code: "fr-FR", name: "Français", flag: "FR" },
  { code: "it-IT", name: "Italiano", flag: "IT" },
  { code: "tr-TR", name: "Türkçe", flag: "TR" },
  { code: "ar-AR", name: "العربية", flag: "SA" },
  { code: "es-ES", name: "Español", flag: "ES" },
  { code: "hi-HI", name: "हिन्दी", flag: "IN" },
  { code: "id-ID", name: "Bahasa Indonesia", flag: "ID" },
  { code: "ja-JA", name: "日本語", flag: "JP" },
  { code: "pt-PT", name: "Português", flag: "PT" },
  { code: "zh-ZH", name: "中文", flag: "CN" },
  { code: "da-DA", name: "Dansk", flag: "DK" },
  { code: "fi-FI", name: "Suomi", flag: "FI" },
  { code: "sv-SV", name: "Svenska", flag: "SE" },
];

export default function LanguageSelector() {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState(languages[0]);
  const [, dispatch] = useStore();

  useEffect(() => {
    const currentLang =
      languages.find((lang) => lang.code === i18n.language) || languages[0];
    setCurrentLanguage(currentLang);
  }, [i18n.language]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng) => {
    console.log("Changing language to:", lng);
    i18n.changeLanguage(lng);
    dispatch({
        type: "SET_LANGUAGE",
        payload: {
          language: lng,
        },
      });
    handleClose();
  };
  const CurrentFlag = countryFlags[currentLanguage.flag];
  return (
    <Box display={"flex"} alignItems={"center"}>
      <IconButton onClick={handleClick}>
        <LanguageIcon sx={{ color:"white", fontSize: { xs: "20px", md: "24px" } }}/>
      </IconButton>
      <Box mr={1} mb={0.2} width={24} height={18}>
        <CurrentFlag title={currentLanguage.name} />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              maxHeight: 300,
              width: "20ch",
            },
          },
        }}
      >
        {languages.map((lang) => {
          const FlagComponent = countryFlags[lang.flag];
          return (
            <MenuItem key={lang.code} onClick={() => changeLanguage(lang.code)}>
              <Box display="flex" alignItems="center">
                <Box width={24} height={18} mr={1}>
                  <FlagComponent title={lang.name} />
                </Box>
                <Typography variant="body2">{lang.name}</Typography>
              </Box>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
}
