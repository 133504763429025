import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip, IconButton, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DefaultPaginationData } from "../../../constants";
import { useNavigate } from "react-router-dom";
import BaseSnackbar from "../../../components/BaseSnackbar";
import { BaseTable, TableFilterContainer } from "../../../components/table";
import { UsersService } from "../../../api/services";
import SearchBar from "../../../components/SearchBar";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddNewButton from "../../../components/buttons/AddNewButton";

export default function ListOnlineUsers() {
  const { t } = useTranslation();
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [paginationData, setPaginationData] = useState(DefaultPaginationData);
  const [loadingData, setLoadingData] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [openSnackbar, setSnackbarStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();

  const TABLE_HEAD = [
    { key: "username", label: t("Username") },
    { key: "password", label: t("Password") },
    { key: "action", label: t("Actions"), align: "right" },
  ];

  const TABLE_FIELD_MAPPING = {
    username: { key: "username", index: 0 },
    password: { key: "password", index: 1 },
  };

  const fetchUsers = () => {
    setLoadingData(true);
    const params = {
      page: paginationData.page + 1,
      size: paginationData.rowsPerPage,
    };

    UsersService.listStoreUsers(params) //TODO 
      .then((response) => {
        const items = response.data.items.map((itemData) => {
          const item = new Array(TABLE_HEAD.length - 1).fill({});
          Object.keys(TABLE_FIELD_MAPPING).forEach((key) => {
            const value = itemData[key];
            item[TABLE_FIELD_MAPPING[key].index] = {
              ...TABLE_FIELD_MAPPING[key],
              value: value || "",
            };
          });
          return item;
        });
        setData(items);
        setTotalCount(response.data.total);
      })
      .catch((err) => {
        setMessage(t("Failed to fetch users"));
        setSnackbarStatus(true);
        console.error(err);
      })
      .finally(() => setLoadingData(false));
  };

  useEffect(() => {
    fetchUsers();
  }, [paginationData]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterData = (data, query) => {
    if (!query) return data;
    return data.filter((row) =>
      ["username"].some((key) => {
        const cellValue = row[TABLE_FIELD_MAPPING[key]?.index]?.value || "";
        return cellValue.toString().toLowerCase().includes(query.toLowerCase());
      })
    );
  };

  const filteredData = filterData(data, searchQuery);

  const getActionItems = (index) => (
    <Box sx={{ minWidth: "80px" }}>
      <Tooltip title={t("Edit User")}>
        <IconButton
          sx={{
            color: theme.palette.neutral,
          }}
          size="small"
          aria-label="edit-user"
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("Details")}>
        <IconButton 
        sx={{
            color: theme.palette.neutral,
          }} 
          size="small" aria-label="details">
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("Delete User")}>
        <IconButton color="secondary" size="small" aria-label="delete-user">
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={message}
        setOpen={setSnackbarStatus}
      />
      <TableFilterContainer
        sx={{
          marginTop: "2vh",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: "20px",
          paddingLeft: "20px",
        }}
      >
        <SearchBar
          searchQuery={searchQuery}
          handleSearchChange={handleSearchChange}
        />
        <AddNewButton
          label={t("add-new-user")}
          onClick={() => navigate("/dt/on/add-new-user")}
        />
      </TableFilterContainer>
      <BaseTable
        head={TABLE_HEAD}
        data={filteredData}
        actionItemPrep={getActionItems}
        pagination={{
          paginationData: { ...paginationData, totalCount: totalCount },
          setPaginationData: setPaginationData,
        }}
        loadingData={loadingData}
      />
    </>
  );
}
