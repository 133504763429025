import { useRoutes, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useStore } from "./store/Store";
import Auth from "./Auth";
import WentWrong from "./pages/WentWrong";
import { isTokenExpired } from "./utils/Util";
import Page404 from "./pages/Page404";
import Landing from "./pages/onlineUser/Landing";
import KioskLayout from "./layouts/kiosk/kioskLayout";
import { useEffect, useState } from "react";
import { Service } from "./api/services";

export default function OnlineStoreRouter({hashCode}) {


  const [store, dispatch] = useStore();
  const location = useLocation();
  const navigate = useNavigate();
  const onlineUser = JSON.parse(localStorage.getItem("onlineUser"));



  const handleAppRedirect = () => {
    if (isTokenExpired(onlineUser?.expires_at)) {
      (async () => {
        await dispatch({ type: "ONLINE_USER_LOGOUT" });
      })();
    }

    if (onlineUser !== null && store?.onlineUser?.token !== null) {
      return <KioskLayout isAdminLayout={true} />;
    } else {
      return <Navigate to={`/dt/${hashCode}/on/login`} />;
    }
  };

  const routes = [
    {
      path: `/dt/${hashCode}/on`,
      element: handleAppRedirect(),
      children: [
        { path: `/dt/${hashCode}/on` , element: <Navigate to={`/dt/${hashCode}/on/landing`} replace /> },
        { path: `/dt/${hashCode}/on/landing`, element: <Landing /> },
      ],
    },
    {
      path: `/dt/${hashCode}/on/login`,
      element:
        (onlineUser === null || onlineUser?.token === null) ? (
          <Auth type="online-login" hashCode = {hashCode} />
        ) : (
          <Navigate to={`/dt/${hashCode}/on`} />
        ),
    },
    { path: "*", element: <Page404 /> },
    { path: "oops", element: <WentWrong /> },
  ];

  return useRoutes(routes);
}
