import { Box, Button, Menu, MenuItem, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Logo from "../../components/Logo";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import LanguageSelector from "../../components/LanguageSelector";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useStore } from "../../store/Store";

const MainStyle = styled("div")(({ theme }) => ({
  height: "70px",
  backgroundColor: theme.palette.primary.darker,
  display: "flex",
  flexGrow: 1,
  alignItems: "center",
  padding: "0 16px",
  justifyContent: "space-between",
}));

export default function Header() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [store, dispatch] = useStore();
  const navigate = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMyAccount = () => {
    handleClose();
    navigate("/myaccount");
  };

  const handleLogout = () => {
    handleClose();

    if (store.partition) {
      dispatch({ type: "PARTITION_LOGOUT" });
      navigate("/login", { replace: true });
    } else if (store.distributor) {
      dispatch({ type: "DISTRIBUTOR_LOGOUT" });
      navigate("/dt/login", { replace: true });
    } else if (store.store) {
      dispatch({ type: "STORE_LOGOUT" });
      navigate("/st/login", { replace: true });
    } else if (store.kiosk) {
      dispatch({ type: "KIOSK_LOGOUT" });
      navigate("/ki/login", { replace: true });
    }
  };

  return (
    <MainStyle>
      <Box
        component={RouterLink}
        to="/"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 2,
          marginLeft: "-15px",
        }}
      >
        <Logo
           sx={{
            width: { xs: 100, md: 150 },
            height: { xs: 40, md: 60 },
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          component={RouterLink}
          to="/subscription"
          variant="contained"
          color="accent"
          sx={{
            fontSize: { xs: '0.6rem', md: '0.8rem' },
            padding: { xs: '3px 6px', md: '6px 12px' },
            width: { xs: '100%', sm: 'auto' }, 
          }}
        >
          {t("subscription")}
        </Button>
        <IconButton size="large" edge="end" onClick={handleMenu}>
          <AccountCircleIcon sx={{ color: "white" }} />
        </IconButton>

        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleMyAccount}>My Account</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
        <LanguageSelector />
      </Box>
    </MainStyle>
  );
}
