import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Box, Drawer, IconButton, useMediaQuery } from "@mui/material";
import Scrollbar from "../../components/Scrollbar";
import MenuIcon from "@mui/icons-material/Menu";
import FixedNavItem from "../../components/FixedNavItem";
import { useTheme } from "@mui/material/styles";

const DrawerStyle = styled(Drawer)(({ theme, open, isMobile }) => ({
  width: isMobile ? 50 : 90,
    overflowX: "hidden",
  border: "0px",
  backgroundColor: theme.palette.primary.darker,
  "& .MuiDrawer-paper": {
    backgroundColor: theme.palette.primary.darker,
    position: "relative",
    border: "0px",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      height: "100%",
      backgroundColor: theme.palette.primary.darker,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "60px",
  "&:hover": {  
    boxShadow: "0px 4px 2px rgba(0, 0, 0, 0.2)",
  },
}));

const StyledMenuIcon = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.lighter,
}));

export default function FixedSidebar({
  isOpen,
  onToggleDrawer,
  handleOpenHoverDrawer,
  setHoverNavTitle,
  config
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleMouseOver = (menuItem) => {
    
    menuItem.children ? handleOpenHoverDrawer(true, menuItem.children) : handleOpenHoverDrawer(false, [])
    setHoverNavTitle(menuItem.title);
  };

  const handleMouseOut = (menuItem) => {
    menuItem.children ? handleOpenHoverDrawer(false, menuItem.children) : handleOpenHoverDrawer(false, [])
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          width: "100%",
          gap: "2%",
        }}
      >
        <Box
          sx={{
            mt: "15px",
            mb: "15px",
          }}
        >
          <StyledMenuIcon onClick={onToggleDrawer}>
            <MenuIcon sx={{ width: 30, height: 30 }} />
          </StyledMenuIcon>
        </Box>

        {config.map((menuItem) => (
          <StyledBox
            key={menuItem.title}
            onMouseEnter={() => handleMouseOver(menuItem)}
            onMouseLeave={() => handleMouseOut(menuItem)}
          >
            <FixedNavItem item={menuItem} />
          </StyledBox>
        ))}
      </Box>
    </Scrollbar>
  );

  return (
    <DrawerStyle variant="permanent" open={isOpen}  isMobile={isMobile}>
      {renderContent}
    </DrawerStyle>
  );
}

FixedSidebar.propTypes = {
  isOpen: PropTypes.bool,
  onToggleDrawer: PropTypes.func,
};
