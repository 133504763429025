import * as Yup from "yup";
import { useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify-icons/eva/eye-fill";
import eyeOffFill from "@iconify-icons/eva/eye-off-fill";
import MailIcon from "@mui/icons-material/Mail";
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { LoadingButton } from "@mui/lab";
// app

import { useTranslation } from "react-i18next";
import { useStore } from "../../../store/Store";
import BaseSnackbar from "../../BaseSnackbar";
import palette from "../../../theme/palette";
import { AuthenticationService } from "../../../api/services";
// ----------------------------------------------------------------------

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& input": {
    color: palette.neutral.lightmost,
    fontWeight: "bold",
    fontSize: "1.4rem",
    padding: "10px 0",
  },
  height: "10vh",
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottomWidth: "4px",
    borderBottomColor: palette.accent.dark,
  },

  "& .MuiInput-underline:after": {
    borderBottomWidth: "4px",
    borderBottomColor: palette.accent.dark,
  },

  "& .MuiInputLabel-formControl": {
    fontWeight: "bold",
    marginTop: "1rem",
  },

  "& .MuiInputLabel-root": {
    transform: "translate(0, 0px) scale(1)",
    transition: "all 0.2s ease-out",
  },
  "& .MuiInputLabel-shrink": {
    transform: "translate(0, -23px) scale(1)",
  },
  "& .MuiFormHelperText-root": {
    fontSize: "0.92rem",
    color: palette.secondary.dark,
    fontWeight: "bold",
    marginTop: "8px",
  },
}));

export default function LoginForm({ loginType, hashCode }) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loginMessage, setLoginMessage] = useState(t("user-not-found"));
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [, dispatch] = useStore();

  const fetchSettings = async (loginResponse) => {
    const loginPayload = {
      token: loginResponse.data.access_token,
      expires_at: loginResponse.data.expires_at,
      refresh_token: loginResponse.data.refresh_token,
      refresh_token_expires_at: loginResponse.data.refresh_token_expires_at,
      refresh_token_issued_at: loginResponse.data.refresh_token_issued_at,
      user: {
        id: loginResponse.data.user_info.id,
        first_name: loginResponse.data.first_name,
        last_name: loginResponse.data.last_name,
        email: loginResponse.data.user_info.email,
        contactsInformation: {
          address1: loginResponse.data.user_info?.properties?.address1,
          address2: loginResponse.data.user_info?.properties?.address2,
          country_id: loginResponse.data.user_info?.properties?.country_id,
          telephone: loginResponse.data.user_info?.properties?.telephone,
        },
      },
    };

    //We can use loginType directly but better use distinct mapping for userType changes etc..
    const payloadType = {
      platform: "platform",
      partition: "partition",
      store: "store",
      distributor: "distributor",
      onlineUser: "onlineUser",
    }[loginType];

    dispatch({
      type: "LOGIN",
      payload: {
        [payloadType]: loginPayload,
      },
    });

    dispatch({
      type: "UPDATE_SELECTED_LANGUAGE",
      payload: {
        //language: loginResponse.data.user.selected_language_code,
        language: "en-US",
      },
    });
  };

  const getFormData = (values) => {
    const formData = new FormData();
    formData.append("username", values.email);
    formData.append("password", values.password);
    return formData;
  };

  const getOnlineUserFormData = (values) => {
    const formData = new FormData();
    formData.append("username", values.username);
    formData.append("password", `${values.password} ${hashCode}`);
    return formData;
  };


  const LoginSchema = Yup.object().shape({
    email: isSubmitted
      ? Yup.string()
          .email(t("email-must-be-valid"))
          .required(t("email-is-required"))
      : Yup.string().email(t("email-must-be-valid")),

    password: isSubmitted
      ? Yup.string()
          .max(32, t("password-long-error"))
          .matches(/^[\S]*$/, t("password-validation"))
          .required(t("password-is-required"))
      : Yup.string()
          .max(32, t("password-long-error"))
          .matches(/^[\S]*$/, t("password-validation")),
  });

  const OnlineLoginSchema = Yup.object().shape({
    username: Yup.string().required(t("username-is-required")),

    password: isSubmitted
      ? Yup.string()
          .max(32, t("password-long-error"))
          .matches(/^[\S]*$/, t("password-validation"))
          .required(t("password-is-required"))
      : Yup.string()
          .max(32, t("password-long-error"))
          .matches(/^[\S]*$/, t("password-validation")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema:
      loginType == "onlineUser" ? OnlineLoginSchema : LoginSchema,
      onSubmit: (values, { setSubmitting, setTouched }) => {

      setIsSubmitted(true);
      setSubmitting(true);
      if ((values.email === "" || values.password === "") && loginType != "onlineUser") {

        setSubmitting(false);
        setTouched({  
          email: true,
          password: true,
        });
        return;
      }
      const payload = loginType == "onlineUser" ? getOnlineUserFormData(values) : getFormData(values);


      let apiService;

      switch (loginType) {
        case "platform":
          apiService = AuthenticationService.platformLogin(payload);
          break;
        case "partition":
          apiService = AuthenticationService.partitionLogin(payload);
          break;
        case "store":
          apiService = AuthenticationService.storeLogin(payload);
          break;
        case "distributor":
          apiService = AuthenticationService.distributorLogin(payload);
          break;
        case "onlineUser":
          apiService = AuthenticationService.onlineUserLogin(payload);
          break;

        default:
          throw new Error(`Unexpected loginType: ${loginType}`);
      }

      apiService
        .then((response) => {
          if (response.status === 200) {
            setLoginMessage(t("login-success"));
            setOpenSnackbar(true);
            fetchSettings(response);
            /* switch(loginType) */
          } else {
            throw new Error("Failed to login");
          }
        })
        .catch((err) => {
          console.error("API Error:", err); // Log API errors
          setLoginMessage(t("login-fail"));
          setOpenSnackbar(true);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <>
      <BaseSnackbar
        open={openSnackbar}
        message={loginMessage}
        setOpen={setOpenSnackbar}
      />
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            {loginType != "onlineUser" ? (
              <CustomTextField
                variant="standard"
                fullWidth
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomWidth: "4px",
                    borderBottomColor: getFieldProps("email").value
                      ? palette.accent.dark
                      : "white",
                  },
                }}
                autoComplete="username"
                type="email"
                label={
                  <Typography color={"primary.light"} variant="h4s">
                    {t("email")}
                  </Typography>
                }
                {...getFieldProps("email")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MailIcon sx={{ mb: 1 }} />
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            ) : (
              <CustomTextField
                variant="standard"
                fullWidth
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomWidth: "4px",
                    borderBottomColor: getFieldProps("username").value
                      ? palette.accent.dark
                      : "white",
                  },
                }}
                autoComplete="username"
                type="username"
                label={
                  <Typography color={"primary.light"} variant="h4s">
                    {t("username")}
                  </Typography>
                }
                {...getFieldProps("username")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MailIcon sx={{ mb: 1 }} />
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.username && errors.username)}
                helperText={touched.username && errors.username}
              />
            )}

            <CustomTextField
              variant="standard"
              fullWidth
              autoComplete="current-password"
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomWidth: "4px",
                  borderBottomColor: getFieldProps("password").value
                    ? palette.accent.dark
                    : "white",
                },
              }}
              type={showPassword ? "text" : "password"}
              label={
                <Typography color={"primary.light"} variant="h4s">
                  {t("password")}
                </Typography>
              }
              {...getFieldProps("password")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ mb: 1 }}
                      onClick={handleShowPassword}
                      edge="end"
                    >
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>
          <br />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{
              backgroundColor: palette.accent.dark,
              "&:hover": {
                backgroundColor: palette.accent.main, // Change to the color you want on hover
              },
              color: palette.accent.darkmost,
              fontWeight: "bold",
              fontSize: "1.2rem",
            }}
          >
            {t("login")}
          </LoadingButton>
        </Form>
      </FormikProvider>
    </>
  );
}
