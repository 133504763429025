import { NavLink } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Container, Stack, Typography, Box, Card, Divider } from "@mui/material";
// components
import Page from "./components/Page";

import { useTranslation } from "react-i18next";
import Login from "./components/authentication/login/Login";
import ForgotPasswordForm from "./components/authentication/forgotPassword/ForgotPasswordForm";
import Logo from "./components/Logo";
import KioskLoginForm from "./components/authentication/login/KioskLogin";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme, backgroundimage }) => ({
  display: "flex",
  alignItems: "start",
  justifyContent: "start",
  minHeight: "100vh",
  backgroundImage: `url(${backgroundimage})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

const KioskContentStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
  
}));
// ----------------------------------------------------------------------

export default function Auth({ type = "login", hashCode  }) {
  const { t } = useTranslation();

  let title, subtitle, link, navigateText, formComponent, navLink;
  let contentStyle = ContentStyle;

  if (type === "platform-login") {
    title = t("login");
    subtitle = t("login");
    link = "/forgot-password";
    formComponent = <Login loginType="platform"/>;
  } else if (type === "partition-login") {
    title = t("parition-login");
    subtitle = t("parition-login");
    link = "/forgot-password";
    formComponent = <Login loginType={"partition"} />;
  }  else if (type === "distributor-login") {
    title = t("distributor-login");
    subtitle = t("distributor-login");
    link = "/forgot-password";
    formComponent = <Login loginType={"distributor"} />;
  }  else if (type === "store-login") {
    title = t("store-login");
    subtitle = t("store-login");
    link = "/forgot-password";
    formComponent = <Login loginType={"store"} />;
  }else if (type === "kiosk-login") {
    title = t("kiosk-login");
    subtitle = t("kiosk-login");
    link = "/forgot-password";
    formComponent = <KioskLoginForm loginType={"kiosk"} />;
    contentStyle = KioskContentStyle;
  } else if (type === "online-login") {
    title = t("online-login");
    subtitle = t("online-login");
    link = "/forgot-password";
    formComponent = <Login loginType={"onlineUser"} hashCode = {hashCode} />;
  }  
  else if (type === "forgot-password") {
    title = t("forgot-password");
    subtitle = t("forgot-password");
    link = "/login";
    navigateText = t("back-to-login");;
    formComponent = <ForgotPasswordForm type={"store"} />;
  } else if (type === "forgot-password-admin") {
    title = t("forgot-password");
    subtitle = t("forgot-password");
    link = "/admin/login";
    navigateText = t("back-to-login");;
    formComponent = <ForgotPasswordForm type={"admin"} />;
  }/* else if (type === "accept-admin-invitation") {
    title = t("accept-admin-invitation");
    subtitle = t("register");
    formComponent = <AcceptAdminInvitation type={"admin"} />;
  } */
  if (link) {
    navLink = (
      <NavLink style={{ marginTop: "12px", textAlign: "center" }} to={link}>
        {" "}
        {navigateText}{" "}
      </NavLink>
    );
  } else {
    navLink = null;
  }
  return (
    <RootStyle title={`${title}`} backgroundimage={`${process.env.PUBLIC_URL}/assets/landing.png`}>
      {type !== "kiosk-login" ? (
      <Container maxWidth="sm" sx={{ mt: "2vh"}}>
        <Card sx={{backgroundColor: "primary.dark", opacity: 0.85}}>
          <ContentStyle>
            <Box sx={{ justifyContent: "center", alignItems: "center" }}>
              <Logo sx={{ width: "80%", ml: "10%", mb: "5%" }} />
            </Box>

            <Stack>
              <Typography variant="h2" color="primary.light" gutterBottom alignSelf={"center"}>
                {subtitle}
              </Typography>
              <Divider sx={{mb: 2}}/>
{/*               <Typography sx={{ color: "secondary.lightmost" }} >
                {t("enter-your-details-below")}
              </Typography> */}
            </Stack>
            <Box mt={2}>{formComponent}</Box>
            {navLink}
            {type === "login" || type === "admin-login" ? (
              <NavLink
                style={{ marginTop: "12px", textAlign: "center" }}
                to={"/forgot-password"}
              >
                {" "}
                {t("forgotten-account")}{" "}
              </NavLink>
            ) : null}
          </ContentStyle>
        </Card>
      </Container>
      ) : (
        <Container maxWidth="lg" sx={{mt: "2vh" }}>
        <Card sx={{backgroundColor: "primary.dark", opacity: 0.85}}>
        <KioskContentStyle>
        <Box sx={{ justifyContent: "center", alignItems: "center" }}>
              <Logo sx={{ width: "35%", m: "auto", mb: "5%" }} />
            </Box>

            <Stack>
              <Typography variant="h2" color="primary.light" gutterBottom alignSelf={"center"}>
                {subtitle}
              </Typography>
              <Divider sx={{mb: 2}}/>
            </Stack>
            <Box mt={2}>{formComponent}</Box>
            {navLink}
          </KioskContentStyle>
          </Card>
          </Container>
      )}
    </RootStyle>
  );
}
