import React, { useEffect, useState } from "react";
import "./App.css";
import Store from "./store/Store";
import ThemeConfig from "./theme";
import api from "./api/Api";
import PlatformRouter from "./PlatformRouter";
import { ErrorBoundary } from "react-error-boundary";
import { Service } from "./api/services";
import { useLocation } from "react-router-dom";
import DistributorRouter from "./DistributorRouter";
import StoreRouter from "./StoreRouter";
import PartitionRouter from "./PartitionRouter";
import KioskRouter from "./KioskRouter";
import OnlineStoreRouter from "./OnlineStoreRouter";
import Page404 from "./pages/Page404";

function ErrorFallback({ error }) {
  window.location.href = "/oops";
  console.error(error);
}

const getApiUrl = () => {
  var request = new XMLHttpRequest();
  request.open("GET", document.location.origin, false);
  request.send();
  if (request.readyState === 4) {
    if (
      request.getResponseHeader("X-Api-Address") === null ||
      request.getResponseHeader("X-Api-Address") === undefined
    ) {
      let isLocal =
        document.location.origin.includes("127.0.0.1") ||
        document.location.origin.includes("localhost");
      return isLocal ? "http://127.0.0.1:8000/api" : document.location.origin;
    } else {
      return request.getResponseHeader("X-Api-Address");
    }
  }
};

const baseUrl = getApiUrl();
api.defaults.baseURL = baseUrl;

export default function App() {

  const [stateData, setStateData] = useState();
  const [router, setRouter] = useState([]);
  const [show404, setShow404] = useState(false); // Add state to control 404 page
  const location = useLocation();
  
  useEffect(() => {
    Service.getSession()
      .then((response) => {
        if (response.status === 200) {
          let sessionType = response.data.session_type;
          let routerComponent;
          let determinedUserType;

          switch (sessionType) {
            case "platform":
              determinedUserType = "platform";
              routerComponent = <PlatformRouter />;
              break;
            default:
              if (location.pathname.includes("/on")) {
                const match = location.pathname.match(/^\/dt\/(\d{6})\/on/);
                let hash_code = match ? match[1] : null;
            
                if (hash_code) {
                  Service.checkOnlineSession(hash_code)
                    .then((response) => {
                      if(response.status === 200){
                        if (response.data["online-session"]){
                          determinedUserType = "online_user";
                          setRouter(<OnlineStoreRouter hashCode={hash_code} />);
                        }else{
                          setShow404(true);
                        }
                      }
                    })
                    
                } else {
                    setShow404(true);
                    return;
                }
              }
              else if (location.pathname.includes("/dt")) {
                determinedUserType = "distributor";
                routerComponent = <DistributorRouter />;
              } else if (location.pathname.includes("/st")) {
                determinedUserType = "store";
                routerComponent = <StoreRouter />;
              } else if (location.pathname.includes("/ki")) {
                determinedUserType = "kiosk";
                routerComponent = <KioskRouter />;
              } else {
                determinedUserType = "partition";
                routerComponent = <PartitionRouter />;
              }
              break;
          }

          const initialStateData = {
            userType: determinedUserType,
          };

          setRouter(routerComponent);
          console.log("Pathname:", location.pathname, "Router Component:", routerComponent);

          setStateData(initialStateData);
        } else {
          throw new Error("Failed to get session!");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [location.pathname]);

  return (
    <Store initialStateData={stateData}>
      <ThemeConfig>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => setStateData(null)}
        >
          {show404 ? <Page404 /> : router}
        </ErrorBoundary>
      </ThemeConfig>
    </Store>
  );
}
