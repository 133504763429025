import api from "../Api"
import urls from "../Urls";


export const getSession = () => {
  return api.get(urls.get_session);
};



export const checkOnlineSession = (hash_code) => {
  return api.get(urls.check_online_session(hash_code));
};